<template>
    <el-form-item :label="config.showLabel ? config.label : ''" :label-width="labelWidth" :required="config.required">
        <!--     特殊处理组件 会对值进行包装等操作-->
        <template v-if="componentList.includes(currentItem.typeId)">
            <component
                :is="currentItem.config.tag"
                v-model="currentItem.config.defaultValue"
                :item="currentItem"
                @input="(val)=>{ $set(config, 'defaultValue', val)}"
            />
        </template>
        <template v-else>
            <!--    其他无特殊处理组件-->
            <component
                :is="currentItem.config.tag"
                v-model="currentItem.config.defaultValue"
                v-bind="{...currentItem}"
                @input="(val)=>{ $set(config, 'defaultValue', val)}"
            />
        </template>
    </el-form-item>
</template>

<script>
import '../../components/FormItem/autoRegister.js'
import draggable from 'vuedraggable'

export default {
    name: 'FormItem',
    components: {
        draggable
    },
    props: [
        'currentItem'
    ],
    data() {
        return {
            // 这些组件值需要被包装 需要特殊处理
            componentList: ['RADIO', 'CHECKBOX', 'SELECT', 'IMAGE_CAROUSEL', 'IMAGE_UPLOAD', 'UPLOAD', 'CASCADER', 'IMAGE_SELECT', 'INPUT', 'DATE', 'RATE', 'SLIDER', 'NUMBER']
        }
    },
    computed: {
        config() {
            return this.currentItem.config
        },
        labelWidth() {
            return this.config.labelWidth ? `${this.config.labelWidth}px` : null
        }
    },
    methods: {
        handleDefaultValue(val) {
            this.$set(this.currentItem.config, 'defaultValue', val)
        },
        getComponentName(item) {
            return item.config.tag
        }
    }
}
</script>

<style scoped>

</style>
