<template>
    <generate-form
        v-if="startParser && flag"
        :question-mode="false"
        :form-conf="formConf"
        :b-form-model="formModel"
        @changeForm="changeForm"
        @submit="submitForm"
    />
</template>

<script>
import { getExpression } from '../../utils/expression'
import _ from 'lodash'
import GenerateForm from '../GenerateForm/index'
import { dbDataConvertForItemJson } from '../../utils/convert'
import { request } from '../../api/request'
import {
    viewFormListResultRequest
} from '@/api/project/data'

export default {
    name: 'BizProjectForm',
    components: {
        GenerateForm
    },
    props: {
        formConfig: {
            formKey: '',
            showBtns: true,
            // 项目种类  1 普通  2模板-->
            formKind: 1
        }
    },
    data() {
        return {
            logicShowTriggerRule: {},
            startParser: false,
            flag: false,
            formModel: {},
            labelFormModel: {},
            parserKey: +new Date(),
            formConf: {
                fields: [],
                logicShowRule: {}, // 逻辑显示规则
                formKey: '',
                formKind: 1,
                formRef: 'elForm',
                formModel: 'formData',
                labelFormModel: 'labelFormData',
                size: 'small',
                labelPosition: 'top',
                labelWidth: 100,
                formRules: 'rules',
                gutter: 15,
                disabled: false,
                span: 24,
                formBtns: true,
                resetBtn: false,
                submitBtnText: '提交',
                submitBtnColor: '#409EFF',
                showNumber: false,
                unFocusedComponentBorder: true,
                // 每页数据
                perPageFields: {},
                // 设置内容
                setting: {}
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        // 父子组件props获取项目key
        if (this.formConfig && this.formConfig.formKey) {
            this.formConf.formKey = this.formConfig.formKey
            if (this.formConfig.formKind) {
                this.formConf.formKind = this.formConfig.formKind
            }
            // iframe 从路由中尝试获取
        } else if (this.$route.query.key) {
            this.formConf.formKey = this.$route.query.key
            if (this.$route.query.kind) {
                this.formConf.formKind = this.$route.query.kind
            }
            this.formConf.formBtns = true
        }
        this.formConf.setting = this.formConfig.setting
        this.formConf.size = window.innerWidth < 480 ? 'medium' : 'small'
    },
    async mounted() {
        await this.setFields()
        // 是模板还是用户自定义表单
        let url = `/user/form/details/${this.formConf.formKey}`
        if (this.formConf.formKind === 2) {
            url = `/form/template/details/${this.formConf.formKey}`
        }

        // 请求表单详细数据
        await request.get(url).then(res => {
            console.info(res)
            if (res.data) {
                // 处理逻辑表单
                let logicItemList = res.data.formLogic ? res.data.formLogic.scheme : []
                const logicItemMap = new Map()
                logicItemList.forEach(item => {
                    logicItemMap.set(item.formItemId, item)
                    this.logicShowTriggerHandle(item)
                })
                // 主题数据
                if (res.data.userFormTheme) {
                    this.formConf.theme = res.data.userFormTheme
                    const {
                        submitBtnText,
                        showNumber,
                        btnsColor
                    } = res.data.userFormTheme
                    if (submitBtnText) this.formConf.theme.submitBtnText = submitBtnText
                    if (showNumber) this.formConf.theme.showNumber = showNumber
                    if (btnsColor) this.formConf.theme.submitBtnColor = btnsColor
                }
                // 处理表单问题项
                const fields = res.data.formItems.map(item => {
                    const projectItem = dbDataConvertForItemJson(item)
                    // 是否是逻辑控制显示字段
                    projectItem.logicShowField = logicItemMap.get(projectItem.formItemId) !== undefined
                    return projectItem
                })
                // 是否有分页 处理分页数据
                this.pageShowHandle(fields)
                if (_.keys(this.formConf.perPageFields).length !== 0) {
                    this.formConf.fields = _.get(this.formConf.perPageFields, 1)
                    this.formConf.formBtns = false
                } else {
                    this.formConf.fields = fields
                }
                if (res.data.form) {
                    this.formConf.title = res.data.form.name
                    document.title = res.data.form.name
                    this.formConf.description = res.data.form.description
                }
                console.info('this.logicShowTriggerRule', this.logicShowTriggerRule)
                this.formConf.logicShowRule = this.logicShowTriggerRule

                this.startParser = true
            }
        })
        this.flag = true

    },
    methods: {
        async setFields() {
            // url携带参数
            const businessJsonObject = this.$route.query
            const businessJson = JSON.stringify(businessJsonObject)
            await viewFormListResultRequest(this.formConfig.formKey, businessJson).then(response => {
                console.info(response)
                if (response.code == 200) {
                    console.info('我是2', this.formModel)
                    let data = response.data
                    if (data && data[0] && data[0]['originalDataString']) {
                        let originalData = JSON.parse(data[0]['originalDataString']) || {}
                        this.formModel = originalData
                    }
                    console.info('我是3', this.formModel)
                }
            })
            console.info('我是', this.formModel)
        },
        // 分页显示数据处理
        pageShowHandle(allFields) {
            // 判断是否存在分页
            const index = allFields.findIndex(item => item.typeId === 'PAGINATION')
            if (index < 0) {
                return
            }
            let curr = 1
            // 每页字段
            const perPageFields = {}
            // 临时存储分页控制数据
            const pageFields = {}
            // 遍历所有字段 把不同页的组件归类到对象的同一个key下
            allFields.forEach(item => {
                let fields = _.get(perPageFields, curr)
                if (item.typeId === 'PAGINATION') {
                    _.set(pageFields, curr, item)
                    // 判断当前页是否有组件 如果没有组件则认为是一个无效的分页组件，页数则不+1
                    if (fields) {
                        item.currPageNum = curr++
                    }
                } else {
                    if (!fields || fields === undefined) {
                        fields = []
                    }
                    fields.push(item)
                    _.set(perPageFields, curr, fields)
                }
            })
            const len = _.keys(perPageFields).length
            // 计算总页数 为每个分页控件绑定数据 并把分页控件添加到每页的控件数据末尾，
            // 并且为最后一页默认生成一个分页控件 否则不拖动将无法返回第一页
            _.keys(perPageFields).forEach(key => {
                const pageItem = _.get(pageFields, key)
                if (pageItem) {
                    const fields = _.get(perPageFields, key)
                    pageItem.totalPageNum = len
                    fields.push(pageItem)
                    _.set(perPageFields, key, fields)
                    // eslint-disable-next-line brace-style
                }
                // 特殊处理最后一页 如果没有主动拖分页 则默认添加一个
                else {
                    let defaultPageItem = _.cloneDeep(_.get(pageFields, 1))
                    const fields = _.get(perPageFields, key)
                    defaultPageItem.totalPageNum = len
                    defaultPageItem.currPageNum = len
                    fields.push(defaultPageItem)
                    _.set(perPageFields, key, fields)
                }
            })
            this.formConf.perPageFields = perPageFields
        },
        /**
     * 处理逻辑显示数据
     */
        logicShowTriggerHandle(logicItem) {
            console.info('logicItem', logicItem)
            if (!logicItem) {
                return
            }
            // 建立触发关系 该字段值发生变化时 哪些问题需要进行逻辑判断 确定是否显示
            logicItem.conditionList.forEach(item => {
                if (Object.keys(item).length === 0) {
                    return
                }
                let rules = this.logicShowTriggerRule[item.formItemId]
                if (!rules) {
                    rules = []
                }
                rules.push({
                    // 被触发显示的字段的ID
                    triggerFormItemId: logicItem.formItemId,
                    //  显示逻辑表达式
                    logicExpression: getExpression(logicItem.conditionList, logicItem.expression)
                })
                this.logicShowTriggerRule[item.formItemId] = rules
            })
        },
        // 内容更新
        changeForm(data) {
            this.$emit('changeForm', data)
        },
        submitForm(data) {
            console.log('formmodel:', data)
            this.$emit('submit', data)
        }
    }
}
</script>
