<template>
    <el-col :class="className" :span="config.span">
        <!-- 动态表格设计模块 start -->
        <template v-if="currentItem.typeId==='SUB_FORM'">
            <div
                class="batch-box"
                @click="emitEvent('activeItem',currentItem)"
            >
                <div class="batch-label">
                    子表单
                    <span class="desc-text">需拖拽组件到这里子表单生效</span>
                </div>
                <draggable
                    :id="currentItem.formId"
                    :list="currentItem.childList"
                    :move="handleMove"
                    class="draggable-box"
                    draggable=".drag-move"
                    tag="div"
                    v-bind="{
                        group: insertAllowed ? 'componentsGroup' : '',
                        ghostClass: 'moving',
                        animation: 180,
                        handle: '.drag-move'
                    }"
                    @change="handleChange($event,currentItem)"
                >
                    <transition-group class="list-main" tag="div">
                        <div v-for="(child,currentIndex) in currentItem.childList"
                             :key="child.vModel"
                             :class="activeId === child.config.formId ? 'active-from-item' : ''"
                             class="drag-move"
                             @click="(event)=>{activeChildItem(event,child,currentIndex,currentItem)}"
                        >
                            <form-item
                                :key="child.renderKey"
                                :current-item="child"
                                :index="currentIndex"
                                class="form-item"
                            />
                            <span class="drawing-item-copy" title="复制" @click="copyChildItem(child)">
                                <i class="el-icon-copy-document" />
                            </span>
                            <span class="drawing-item-delete" title="删除" @click="deleteChildItem(index)">
                                <i class="el-icon-delete" />
                            </span>
                        </div>
                    </transition-group>
                </draggable>
            </div>
            <span class="drawing-item-copy" title="复制" @click="emitEvent('copyItem',currentItem,drawingList)">
                <i class="el-icon-copy-document" />
            </span>
            <span class="drawing-item-delete" title="删除" @click="emitEvent('deleteItem',currentItem,drawingList)">
                <i class="el-icon-delete" />
            </span>
        </template>

        <template v-else>
            <form-item
                :key="currentItem.renderKey"
                :current-item="currentItem"
                :index="index"
                class="form-item"
                @click.native="emitEvent('activeItem',currentItem)"
            />
            <span class="drawing-item-copy" title="复制" @click="emitEvent('copyItem',currentItem,drawingList)">
                <i class="el-icon-copy-document" />
            </span>
            <span class="drawing-item-delete" title="删除" @click="emitEvent('deleteItem',currentItem,drawingList)">
                <i class="el-icon-delete" />
            </span>
        </template>
    </el-col>
</template>

<script>
import draggable from 'vuedraggable'
import FormItem from './FormItem'
import _ from 'lodash'
import {deepClone} from '../../utils'
import {jsonSimpleClone} from '../../utils'

export default {
    name: 'FormItemLayout',
    components: {
        FormItem,
        draggable
    },
    props: [
        'currentItem',
        'index',
        'drawingList',
        'activeId',
        'formConf'
    ],
    data() {
        return {
            insertAllowed: true
        }
    },
    computed: {
        config() {
            return this.currentItem.config
        },
        className() {
            // 是否选中 选中添加选中样式
            let className = this.activeId === this.config.formId ? 'drawing-item active-from-item' : 'drawing-item'
            // 是否有边框样式
            if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
            return className
        }
    },
    methods: {
        emitEvent(name, val, list) {
            this.$emit(name, val, list)
        },
        activeChildItem(event, child, index, currentItem) {
            child.isChild = true
            child.childIndex = index
            child.parentItem = jsonSimpleClone(currentItem)
            this.emitEvent('activeItem', child)
            event.stopPropagation()
        },
        copyChildItem(item) {
            let clone = deepClone(item)
            clone = this.createIdAndKey(clone)
            this.currentItem.childList.push(clone)
            this.$emit('change', this.currentItem)
        },
        deleteChildItem(index) {
            this.currentItem.childList.splice(index, 1)
            this.$emit('change', this.currentItem)
        },
        handleChange(evt, parent) {
            // 如果有不支持子表单的组件 可以在这里给他从数组移除
            this.$emit('change', parent)
        },
        handleMove(e, originalEvent) {
            // 子表单组件不允许向外拖拽
            if (e.to.className === 'drawing-board') {
                return false
            }
            return true
        },
        createIdAndKey(item) {
            const config = item.config
            // 使用组件类型+时间戳 为ID
            config.formId = `${_.toLower(item.typeId)}-${new Date().getTime()}`
            config.renderKey = `${config.formId}${+new Date()}` // 改变renderKey后可以实现强制更新组件
            item.vModel = `${config.formId}`
            return item
        }
    }
}
</script>

<style scoped>

</style>
