<template>
    <div>
        <!-- 组件属性 -->
        <el-form
            label-width="90px"
            size="small"
        >
            <el-form-item
                v-if="activeData.config.componentName !== undefined"
                label="组件名"
            >
                {{ activeData.config.componentName }}
            </el-form-item>
            <el-form-item
                v-if="
                    activeData.config.label !== undefined &&
                        activeData.config.showLabel !== false "
                label="标题"
            >
                <el-input
                    v-model="activeData.config.label"
                    placeholder="请输入标题"
                    @input="changeRenderKey"
                />
            </el-form-item>
            <el-form-item
                v-if="activeData.placeholder !== undefined"
                label="占位提示"
            >
                <el-input
                    v-model="activeData.placeholder"
                    placeholder="请输入占位提示"
                    @input="changeRenderKey"
                />
            </el-form-item>
            <el-form-item
                v-if="activeData.config.span !== undefined"
                label="表单栅格"
            >
                <el-slider
                    v-model="activeData.config.span"
                    :marks="{ 12: '' }"
                    :max="24"
                    :min="1"
                />
            </el-form-item>
            <el-form-item
                v-if="
                    activeData.config.layout === 'rowFormItem' &&
                        activeData.gutter !== undefined
                "
                label="栅格间隔"
            >
                <el-input-number
                    v-model="activeData.gutter"
                    :min="0"
                    placeholder="栅格间隔"
                />
            </el-form-item>
            <el-form-item
                v-if="
                    activeData.config.layout === 'rowFormItem' &&
                        activeData.type !== undefined
                "
                label="布局模式"
            >
                <el-radio-group v-model="activeData.type">
                    <el-radio-button label="default" />
                    <el-radio-button label="flex" />
                </el-radio-group>
            </el-form-item>
            <el-form-item
                v-if="
                    activeData.justify !== undefined && activeData.type === 'flex'"
                label="水平排列"
            >
                <el-select
                    v-model="activeData.justify"
                    :style="{ width: '100%' }"
                    placeholder="请选择水平排列"
                >
                    <el-option
                        v-for="(item, index) in justifyOptions"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="activeData.align !== undefined && activeData.type === 'flex'"
                label="垂直排列"
            >
                <el-radio-group v-model="activeData.align">
                    <el-radio-button label="top" />
                    <el-radio-button label="middle" />
                    <el-radio-button label="bottom" />
                </el-radio-group>
            </el-form-item>
            <el-form-item
                v-if="
                    activeData.vModel !== undefined &&
                        activeData.config.showDefaultValue !== false"
            >
                <span slot="label">
                    默认值
                    <el-tooltip class="item" content="默认值在编辑器中点选组件设置，无法再此处输入" effect="dark" placement="top-start">
                        <i class="el-icon-warning" />
                    </el-tooltip>
                </span>
                <el-input
                    :value="setDefaultValue(activeData.config.defaultValue)"
                    placeholder="请输入默认值"
                    readonly
                    @input="onDefaultValueInput"
                >
                    <el-button slot="append" icon="el-icon-close" @click="clearDefaultValue" />
                </el-input>
            </el-form-item>
            <el-form-item
                v-if="
                    activeData.config.showLabel !== undefined &&
                        activeData.config.labelWidth !== undefined"
                label="显示标签"
            >
                <el-switch v-model="activeData.config.showLabel" />
            </el-form-item>

            <el-form-item
                v-if="activeData.clearable !== undefined"
                label="能否清空"
            >
                <el-switch v-model="activeData.clearable" />
            </el-form-item>

            <el-form-item
                v-if="activeData.readonly !== undefined"
                label="是否只读"
            >
                <el-switch v-model="activeData.readonly" />
            </el-form-item>

            <el-form-item
                label="分值"
            >
                <el-input-number v-model="activeData.score" label="分值" placeholder="分值输入" />
            </el-form-item>

            <el-form-item v-if="activeData['color'] !== undefined" label="颜色">
                <el-color-picker v-model="activeData['color']" />
            </el-form-item>

            <el-form-item
                v-if="activeData.config.required !== undefined &&
                    activeData.config.showRequired !== false"
                label="是否必填"
            >
                <el-switch v-model="activeData.config.required" />
            </el-form-item>
            <component
                :is="getComponent"
                v-if="getComponent"
                :key="activeData.vModel"
                :active-data="activeData"
                :form-conf="formConf"
                @add-fields="(val)=>{$emit('add-fields',val)}"
            />
            <template
                v-if="
                    Array.isArray(activeData.config.regList) &&
                        activeData.config.showRegList !== false "
            >
                <el-divider>正则校验</el-divider>
                <div
                    v-for="(item, index) in activeData.config.regList"
                    :key="index"
                    class="reg-item"
                >
                    <span
                        class="close-btn"
                        @click="activeData.config.regList.splice(index, 1)"
                    >
                        <i class="el-icon-close" />
                    </span>
                    <el-form-item label="表达式">
                        <span slot="label">
                            表达式
                            <el-tooltip class="item" content="输入JS正则表达式，如用户名4-20位字母 /^[a-zA-Z]{4,20}$/" effect="dark"
                                        placement="top-start"
                            >
                                <i class="el-icon-warning" />
                            </el-tooltip>
                        </span>
                        <el-input v-model="item.pattern" placeholder="请输入正则" />
                    </el-form-item>
                    <el-form-item label="错误提示" style="margin-bottom: 0">
                        <el-input v-model="item.message" placeholder="请输入错误提示" />
                    </el-form-item>
                </div>
                <div style="margin-left: 20px">
                    <el-button
                        icon="el-icon-circle-plus-outline"
                        type="text"
                        @click="addReg"
                    >
                        添加正则
                    </el-button>
                </div>
            </template>
        </el-form>
    </div>
</template>

<script>
import {isArray} from 'util'
import {isNumberStr} from '../../utils'
import {debounce} from 'throttle-debounce'
import './ItemConfig/autoRegister.js'
import _ from 'lodash'

export default {
    name: 'FormItemConfig',
    props: ['activeData', 'formConf'],
    data() {
        return {
            loading: null,
            justifyOptions: [
                {
                    label: 'start',
                    value: 'start'
                },
                {
                    label: 'end',
                    value: 'end'
                },
                {
                    label: 'center',
                    value: 'center'
                },
                {
                    label: 'space-around',
                    value: 'space-around'
                },
                {
                    label: 'space-between',
                    value: 'space-between'
                }
            ],
            layoutTreeProps: {
                label(data, node) {
                    const config = data.config
                    return data.componentName || `${config.label}: ${data.vModel}`
                }
            }
        }
    },
    computed: {
        getComponent() {
            const prefix = 'config-item-'
            const {typeId} = this.activeData
            // 无配置项的组件
            if (['PAGINATION', 'SIGN_PAD', 'PROVINCE_CITY', 'INPUT_MAP', 'PHONE_VERIFICATION'].includes(typeId)) {
                return null
            }
            if (['INPUT', 'TEXTAREA'].includes(typeId)) {
                return `${prefix}input`
            }

            // if(['SELECT'].includes(typeId)){
            //   result = 'select'
            // }
            // if(['CASCADER'].includes(typeId)){
            //   result = 'cascader'
            // }
            // if(['checkbox'].includes(typeId)){
            //   result = 'checkbox'
            // }
            return prefix + _.lowerCase(typeId).replace(' ', '-')
        }
    },
    watch: {
        activeData: {
            handler(newValue, oldValue) {
                // 切换选中时不触发
                if (newValue.config.formId === oldValue.config.formId) {
                    if (newValue) {
                        this.dataChange(newValue)
                    }
                }
            },
            deep: true
        }
    },
    methods: {
        addReg() {
            this.activeData.config.regList.push({
                pattern: '',
                message: ''
            })
        },
        setOptionValue(item, val) {
            item.value = isNumberStr(val) ? +val : val
        },
        setDefaultValue(val) {
            if (Array.isArray(val)) {
                return val.join(',')
            }
            // if (['string', 'number'].indexOf(typeof val) > -1) {
            //   return val
            // }
            if (val instanceof Object) {
                return JSON.stringify(val)
            }
            if (typeof val === 'boolean') {
                return `${val}`
            }
            return val
        },
        clearDefaultValue() {
            let defaultValue = null
            if (Array.isArray(this.activeData.config.defaultValue)) {
                defaultValue = []
            }
            this.$set(
                this.activeData.config,
                'defaultValue', defaultValue
            )
        },
        onDefaultValueInput(str) {
            if (isArray(this.activeData.config.defaultValue)) {
                // 数组
                this.$set(
                    this.activeData.config,
                    'defaultValue',
                    str.split(',')
                        .map(val => (isNumberStr(val) ? +val : val))
                )
            } else if (['true', 'false'].indexOf(str) > -1) {
                // 布尔
                this.$set(this.activeData.config, 'defaultValue', JSON.parse(str))
            } else {
                // 字符串和数字
                this.$set(
                    this.activeData.config,
                    'defaultValue',
                    isNumberStr(str) ? +str : str
                )
            }
        },
        dataChange: debounce(430, false, function(val) {
            this.$emit('data-change', val)
        }),
        changeRenderKey() {
            // eslint-disable-next-line vue/no-mutating-props
            this.activeData.config.renderKey = +new Date()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/index";
</style>
